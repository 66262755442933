import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  allData: [],
  real: [],
  cat: [],
  category: [],
  static: null,
  next: 1,
};

const Design365 = createSlice({
  name: "365design",
  initialState,
  reducers: {
    setDesignData(state, action) {
      state.data = action.payload;
      state.next = action.payload.nextRowsBool;
    },
    updateDesignData(state, action) {
      state.real = arrayUnique(state.real.concat(action.payload));
    },
    setCat(state, action) {
      state.cat = action.payload;
    },
    setCatgory(state, action) {
      state.category = action.payload.categories;
      state.static = action.payload.DesignHeaderContent;
    },
    updateDesignLikes(state, action) {
      let prod = state.data.projcts.find((p) => p.id === action.payload);

      if (prod) {
        if (prod.ip) {
          prod.ip = 0;
          prod.likes -= 1;
        } else {
          prod.ip = 1;
          prod.likes += 1;
        }
      }
    },
  },
});

export const DesignActions = Design365.actions;
export default Design365.reducer;

//delete Array Dublicate
function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id) a.splice(j--, 1);
    }
  }
  return a;
}
