import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// import "./header365.scss";
import { useSelector } from "react-redux";
import Category from "./Category";
import useWidth from "../../Hooks/useWidth";
// import Products from "./Products";
import axiosConfig from "../../axiosConfig";
import Skeleton from "react-loading-skeleton";

const Header365 = ({ data, setSubActive }) => {
  const size = useWidth();
  const [active, setActive] = useState({
    design: "",
    content: "",
    cat: "",
    avatar: "",
    links: "",
    product: "",
  });
  const [footerContent, setFooterContent] = useState({});
  const linkType = useSelector((state) => state.linkactive.type);
  function searchToggle() {
    document.querySelector(".searchbar").classList.toggle("active");
  }
  function sideToggle() {
    document.querySelector(".sidebar").classList.toggle("active");
  }

  useEffect(() => {
    let offset = 0;
    let scrollFunction = (e) => {
      if (window.scrollY > offset) {
        document.querySelector(".header_one").classList.add("active");
      } else {
        document.querySelector(".header_one").classList.remove("active");
      }
      if (window.scrollY > 200) {
        document.querySelector(".scroll_top").style.display = "flex";
      } else {
        document.querySelector(".scroll_top").style.display = "none";
      }
      offset = window.scrollY;
    };

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);
  useEffect(() => {
    switch (linkType) {
      case "design":
        setActive({
          design: "active",
          content: "",
          cat: "",
          links: "",
          product: "",
          avatar: "",
        });
        break;
      case "content":
        setActive({
          design: "",
          content: "active",
          cat: "",
          links: "",
          product: "",
          avatar: "",
        });
        break;
      case "links":
        setActive({
          design: "",
          content: "",
          cat: "",
          links: "active",
          product: "",
          avatar: "",
        });
        break;
      case "product":
        setActive({
          design: "",
          content: "",
          cat: "",
          links: "",
          product: "active",
          avatar: "",
        });
        break;
      case "cat":
        setActive({
          design: "",
          content: "",
          cat: "active",
          links: "",
          product: "",
          avatar: "",
        });
        break;
      case "active":
        setActive({
          design: "",
          content: "",
          cat: "",
          links: "",
          product: "",
          avatar: "active",
        });
        break;
      default:
        break;
    }
  }, [linkType]);

  function getData() {
    axiosConfig
      .get(`/frontend/footer`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        setFooterContent(res.data.data.FooterContent);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`header_one`}>
      <Container className=" h-100">
        <Row className="align-items-center justify-content-between h-100">
          <Col xs="2" className="p-0 d-flex align-items-center gap-4">
            <NavLink to="/365design">
              {data ? (
                <img src={data?.lightImage} alt="" />
              ) : (
                <Skeleton circle width={60} height={60} />
              )}
            </NavLink>
            <h1 className="fs-5 m-0">
              {data?.title || <Skeleton height={24} width={95} />}
            </h1>
          </Col>
          <Col xs={8} className="navs d-none d-lg-block">
            <Row className="navs align-items-center justify-content-between h-100 fs-6 ">
              <ul className="navbar_one">
                <li className="nav_item">
                  <Link
                    className={`nav_item_link ${active.design}`}
                    to="/365design"
                  >
                    365 Days
                  </Link>
                </li>
                <li className="nav_item">
                  <Link
                    className={`nav_item_link ${active.avatar}`}
                    to="/uiavatar"
                  >
                    UI Avatar
                  </Link>
                </li>
                <li className="nav_item cat__parent">
                  <Link
                    className={`nav_item_link ${active.cat}`}
                    to="/365design/categories"
                  >
                    Categories
                  </Link>
                  <div className="cat__container">
                    <Category />
                  </div>
                </li>

                <li className="nav_item product__parent">
                  <Link
                    className={`nav_item_link ${active.product}`}
                    to="/365design/products"
                  >
                    Products
                  </Link>
                  {/* <div className="product__container">
                    <Products />
                  </div> */}
                </li>

                <li className="nav_item">
                  <Link className={`nav_item_link ${active.links}`} to="/links">
                    Links
                  </Link>
                </li>
                <li className="nav_item nav_item_btn ">
                  <Link
                    className={`nav_item_link`}
                    onClick={(e) => (e.preventDefault(), setSubActive(true))}
                    to="/#"
                  >
                    Subscribe
                  </Link>
                </li>
                <li className="nav_item nav_item_btn ">
                  <button className="toggle-btn" onClick={sideToggle}>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 6V7.66667H23V6H3ZM3 12.6667V14.3333H23V12.6667H3ZM3 19.3333V21H23V19.3333H3Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </Row>
          </Col>
          <Col className="toggle_mob p-0 ">
            <button className="toggle-btn" onClick={sideToggle}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 6V7.66667H23V6H3ZM3 12.6667V14.3333H23V12.6667H3ZM3 19.3333V21H23V19.3333H3Z"
                  fill="black"
                />
              </svg>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header365;
