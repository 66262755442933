import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
// import HomeCard365 from "../../../Components/365HomeCard/HomeCard365";
// import Header from "../../../Components/Header/Header";
import CategoryPageItem from "../../../Components/Resourses/CategoryPageItem/CategoryPageItem";
// import HomeCard365 from '../../../Components/365HomeCard/HomeCard365'
// import Header from '../../../Components/Header/Header'
// import CategoryWrapper from "../../../Components/Resourses/CategoryWrapper/CategoryWrapper";
import "./CategoryResoursesPage.scss";
import ResoursesCategoryBreadCrumb from "../../../Components/Resourses/ResoursesCategoryBreadCrumb/ResoursesCategoryBreadCrumb";
import { LinkActive } from "../../../store/linkactive";
import { useParams } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import axios from "axios";

const CategoryResoursesPage = () => {
  const [dataResourses, setDataResourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [next, setNext] = useState(true);
  const [perPage, setPerPage] = useState(12);
  const [slicePage, setSlicePage] = useState([]);
  useEffect(() => {
    dispatch(LinkActive.setType("design"));
    window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const sliderContent = {
  //   img: "white",
  //   head: "Our work demonstrates our efficiency and speaks about us",
  //   text: "We devote all of our efforts to making our work satisfactory for you. The most beautiful thing is to let our work speak for us.",
  //   buttonText: "",
  //   buttonLink: "",
  // };

  const getMoreData = () => {
    setTimeout(() => {
      setLoading(true);
    }, 10000);

    setPerPage(perPage + 12);
    setLoading(false);
  };

  const params = useParams();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}frontend/resources/category`, {
        params: { search: `${params.id.split("_").join(" ")}` },
      })
      .then((res) => {
        setDataResourses(res.data.data);
      })
      .catch((err) => {
      });
  }, [params]);

  useEffect(() => {
    let cardsLength = dataResourses?.InnerPage?.length;
    if (perPage >= cardsLength) {
      setNext(false);
    }
    setSlicePage(dataResourses?.InnerPage?.slice(0, perPage));
  }, [dataResourses?.InnerPage, perPage, next]);


  return (
    <div>
      <ResoursesCategoryBreadCrumb data={dataResourses} />
      <Container className=" card365_cont ">
        <Row>
          {slicePage?.map((card) => (
            <>
              <Col
                xl="3"
                lg="4"
                md="6"
                sm="6"
                xs="12"
                className="category-page-item"
                data-aos="fade-up"
                data-aos-delay="500"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <CategoryPageItem data={card} />
              </Col>
            </>
          ))}
          {/* <Col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            className="category-page-item"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <CategoryPageItem />
          </Col>
          <Col
            xl="3"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            className="category-page-item"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <CategoryPageItem />
          </Col> */}
        </Row>
        {next ? (
          <>
            {loading ? (
              <div className="spin">
                <InfinitySpin color="#3aaa35" width="150" height="45" />
              </div>
            ) : (
              <button className="load_more" onClick={getMoreData}>
                Load More
              </button>
            )}
          </>
        ) : (
          <h2 className="notload-h2 p-3">You've reached the end of the list</h2>
        )}
      </Container>

      {/* <Header type="365" /> */}
      {/* <HomeCard365 /> */}
    </div>
  );
};

export default CategoryResoursesPage;
