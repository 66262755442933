import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import "./infocard.scss";

const InfoCard = (props) => {
  function increaseClicks(id) {
    axios.post(`${process.env.REACT_APP_API}frontend/365Dsign/click`, { id });
  }

  function increaseDownloads(id) {
    axios.post(`${process.env.REACT_APP_API}frontend/365Dsign/download`, {
      id,
    });
  }
  return (
    <>
      {props.data ? (
        <div
          className="info_card sticky-lg-top"
          data-aos="fade-left"
          data-aos-once="true"
        >
          <div className="info_card_head">
            <p className="info_card_head_date">
              <i className="fas fa-calendar-alt"></i>{" "}
              <span>{props.data.date}</span>
            </p>
            <h2 className="info_card_head_title">{props.data.name}</h2>
            <p className="info_card_head_cearor">{props.data.createdBy}</p>
          </div>
          <div className="info_card_av">
            <p>{props.data.availabilityPrograms}</p>
          </div>
          <div className="info_card_desc">
            <p>{props.data.smallDescription}</p>
          </div>
          <div
            className="info_card_btns"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <Link
              to="/contact-us?scroll=true"
              onClick={() => increaseClicks(props?.data?.id)}
              className="get_in_touch"
            >
              <button>Get in touch</button>
            </Link>
            {props.data.link ? (
              <Link
                to="/contact-us?scroll=true&open=hire-us"
                className="get_in_touch"
              >
                Make Your Order Now
              </Link>
            ) : (
              <button className="download mb-3" target="blank">
                Make Your Order Now
              </button>
            )}

            {props.data.state === "free" ? (
              <>
                {props.data.link ? (
                  <a
                    className="get_in_touch"
                    href={props.data.link}
                    onClick={() => increaseDownloads(props?.data?.id)}
                    target="blank"
                  >
                    Download
                  </a>
                ) : (
                  <a className="download" target="blank">
                    Download
                  </a>
                )}
              </>
            ) : (
              <a href={props.data.link} target="blank" className="get_in_touch">
                <span className="get_in_touch">${props.data.price}</span>
              </a>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InfoCard;
