import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import HomeCard365 from '../../../Components/365HomeCard/HomeCard365'
// import Header from '../../../Components/Header/Header'
// import HomeCard365 from '../../../Components/365HomeCard/HomeCard365'
// import Header from '../../../Components/Header/Header'
// import LayoutiSlider from '../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider'
import CategoryWrapper from "../../../Components/Resourses/CategoryWrapper/CategoryWrapper";
import ResoursesHeader from "../../../Components/Resourses/ResoursesHeader/ResoursesHeader";
import { LinkActive } from "../../../store/linkactive";
const HomeResourses = () => {
  const [dataHeaderContent, setDataHeaderContent] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinkActive.setType("design"));
    window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //   const sliderContent = {
  //     img:"white",
  //     head:'Our work demonstrates our efficiency and speaks about us',
  //     text:"We devote all of our efforts to making our work satisfactory for you. The most beautiful thing is to let our work speak for us.",
  //     buttonText:"",
  //     buttonLink:""
  // }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}frontend/resources`, {
        headers: {
          "Content-Type": "application/json",
          language: `en`,
        },
      })
      .then((res) => {
        if (res.data) {
          setDataHeaderContent(res.data.data);
        }
      })
      .catch((err) => {
      });
  }, []);

  return (
    <div>
      <ResoursesHeader data={dataHeaderContent?.headerContent} />
      {dataHeaderContent?.categories?.map((category) => (
        <>
          <CategoryWrapper data={category} />
        </>
      ))}
    </div>
  );
};

export default HomeResourses;
