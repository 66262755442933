import React from "react";
import { ReactComponent as SuccessPopUp } from "../../../assets/images/successPopUp.svg";
import { ReactComponent as FaliurePopUp } from "../../../assets/images/failurePopUp.svg";
import styles from "./PopUp.module.css";
function PopUp({ isOpen, closeModal, type, msg }) {
  return (
    <div
      className={`${styles["popup"]} ${isOpen ? styles["popup-active"] : ""}`}
    >
      <div
        className={styles["popup-background-color"]}
        onClick={closeModal}
      ></div>

      <div className={styles["popup-container"]}>
        <svg
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.4919 96C74.721 96 95.9839 74.7335 95.9839 48.5C95.9839 22.2665 74.721 1 48.4919 1C22.2629 1 1 22.2665 1 48.5C1 74.7335 22.2629 96 48.4919 96Z"
            stroke="#3AAA35"
            stroke-width="2"
          />
          <path
            d="M60.8207 40.2128L41.8207 59.2128L35.3994 52.7915"
            stroke="#3AAA35"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className={styles["popup-header"]}>
          {msg?.descriptionSuccess && (
            <p className={styles["popup-header__desc"]}>
              {msg?.descriptionSuccess}
            </p>
          )}
        </div>

        <div className={styles["popup-footer__close-container"]}>
          <button
            type="button"
            onClick={closeModal}
            className={styles["popup-footer__close-button"]}
          >
            {type == "success" ? "Cancel" : "Try Again"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
