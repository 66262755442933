import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./headerone.scss";
import { useSelector } from "react-redux";
import { ReactComponent as LearnLogo } from "../../assets/images/learnLogo.svg";
import { ReactComponent as MenuDark } from "../../assets/images/MenuWhite.svg";

const HeaderTerms = ({ setActiveProd, activeProd, setSubActive }) => {
  const [active, setActive] = useState({ ui: "", content: "" });
  const linkType = useSelector((state) => state.linkactive.type);
  function sideToggle() {
    document.querySelector(".sidebar").classList.toggle("active");
  }
  useEffect(() => {
    let offset = 0;
    let scrollFunction = (e) => {
      if (window.scrollY > offset) {
        document.querySelector(".header_one_terms").classList.add("active");
        setActiveProd(false);
      } else {
        document.querySelector(".header_one_terms").classList.remove("active");
      }
      if (window.scrollY > 200) {
        document.querySelector(".scroll_top").style.display = "flex";
      } else {
        document.querySelector(".scroll_top").style.display = "none";
      }
      offset = window.scrollY;
    };
    window.addEventListener("scroll", scrollFunction);
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  useEffect(() => {
    switch (linkType) {
      case "ui":
        setActive({ ui: "active", content: "" });
        break;
      case "content":
        setActive({ ui: "", content: "active" });
        break;
      default:
        break;
    }
  }, [linkType]);

  const activeProducts = (e) => {
    setActiveProd((a) => !a);
  };

  return (
    <div className={`header_one_terms`}>
      <Container className=" h-100" style={styles.container}>
        <Row className="align-items-center justify-content-between h-100">
          <Col xs="2" className="p-0">
            <NavLink to="">
              <svg
                width="150"
                height="75"
                viewBox="0 0 216 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3151_13110)">
                  <path
                    d="M75 75H0V0H75V75ZM3.04095 71.9591H71.9591V3.04095H3.04095V71.9591Z"
                    fill="white"
                  />
                  <path
                    d="M46.9279 58.2804H27.4253V16.7207H40.1111V45.5945H46.9279C50.425 45.5945 53.2683 48.4378 53.2683 51.9349C53.2683 55.432 50.425 58.2753 46.9279 58.2753V58.2804ZM30.4662 55.2394H46.9279C48.7474 55.2394 50.2274 53.7595 50.2274 51.94C50.2274 50.1205 48.7474 48.6406 46.9279 48.6406H37.0702V19.7667H30.4662V55.2394Z"
                    fill="white"
                  />
                  <path
                    d="M33.5724 44.4388C26.8823 44.4388 21.439 38.9955 21.439 32.3054C21.439 31.4641 22.1181 30.7849 22.9594 30.7849C23.8008 30.7849 24.4799 31.4641 24.4799 32.3054C24.4799 37.3179 28.5599 41.3979 33.5724 41.3979C38.5849 41.3979 42.6648 37.3179 42.6648 32.3054C42.6648 29.1175 40.9619 26.1171 38.2149 24.4851C37.4952 24.0543 37.257 23.1217 37.6878 22.402C38.1186 21.6823 39.0511 21.4441 39.7708 21.8749C43.4352 24.0543 45.7058 28.0531 45.7058 32.3105C45.7058 39.0006 40.2625 44.4439 33.5724 44.4439V44.4388Z"
                    fill="#26E71E"
                  />
                  <path
                    d="M40.1103 18.2402H37.0693V33.9062H40.1103V18.2402Z"
                    fill="white"
                  />
                  <path
                    d="M30.4662 31.8398H27.4253V47.1105H30.4662V31.8398Z"
                    fill="white"
                  />
                </g>
                <path
                  d="M113.594 42.1846V46.8753H98.7148V20.7305H104.021V42.1846H113.594Z"
                  fill="white"
                />
                <path
                  d="M121.079 47.3367C117.106 47.3367 115.12 45.2349 115.12 41.0312C115.12 39.0319 115.645 37.5965 116.696 36.725C117.773 35.8279 119.49 35.3024 121.848 35.1486L125.962 34.841V33.6491C125.962 32.8802 125.783 32.3419 125.424 32.0343C125.091 31.7267 124.514 31.5729 123.694 31.5729C122.848 31.5729 121.682 31.6114 120.195 31.6883C118.708 31.7395 117.465 31.8036 116.466 31.8805L116.312 28.3048C119.08 27.5615 121.695 27.1898 124.155 27.1898C126.616 27.1898 128.385 27.7025 129.461 28.7278C130.563 29.753 131.114 31.3935 131.114 33.6491V41.6464C131.166 42.2872 131.268 42.7229 131.422 42.9536C131.601 43.1843 131.922 43.3509 132.383 43.4534L132.229 47.3367C130.897 47.3367 129.833 47.247 129.038 47.0676C128.269 46.8881 127.5 46.5293 126.731 45.991C124.86 46.8881 122.976 47.3367 121.079 47.3367ZM122.194 43.1458C123.168 43.1458 124.232 42.9921 125.386 42.6845L125.962 42.5307V38.4167L122.464 38.7243C121.028 38.8525 120.31 39.6214 120.31 41.0312C120.31 42.441 120.938 43.1458 122.194 43.1458Z"
                  fill="white"
                />
                <path
                  d="M142.338 46.8753H138.724L133.994 27.6512H139.07L142.568 42.4922H143.453L146.951 27.6512H152.027L145.26 54.9495H140.223L142.338 46.8753Z"
                  fill="white"
                />
                <path
                  d="M162.53 47.3367C159.634 47.3367 157.429 46.4652 155.917 44.7222C154.43 42.9536 153.687 40.4545 153.687 37.2248C153.687 34.0208 154.43 31.5473 155.917 29.8043C157.429 28.0613 159.634 27.1898 162.53 27.1898C165.427 27.1898 167.618 28.0613 169.105 29.8043C170.617 31.5473 171.373 34.0208 171.373 37.2248C171.373 40.4545 170.617 42.9536 169.105 44.7222C167.618 46.4652 165.427 47.3367 162.53 47.3367ZM162.53 42.9536C163.761 42.9536 164.671 42.505 165.26 41.6079C165.85 40.7108 166.144 39.2498 166.144 37.2248C166.144 35.1999 165.85 33.7517 165.26 32.8802C164.671 32.0087 163.761 31.5729 162.53 31.5729C161.3 31.5729 160.39 32.0087 159.8 32.8802C159.211 33.7517 158.916 35.1999 158.916 37.2248C158.916 39.2498 159.211 40.7108 159.8 41.6079C160.39 42.505 161.3 42.9536 162.53 42.9536Z"
                  fill="white"
                />
                <path
                  d="M181.379 47.3367C179.738 47.3367 178.444 47.0035 177.495 46.3371C176.573 45.6706 175.919 44.6453 175.534 43.2612C175.15 41.8771 174.958 40.0315 174.958 37.7246V27.6512H180.11V37.8015C180.11 39.1857 180.174 40.2238 180.302 40.9158C180.43 41.5823 180.687 42.0565 181.071 42.3384C181.481 42.5948 182.096 42.7229 182.917 42.7229C183.506 42.7229 184.032 42.6717 184.493 42.5691C184.98 42.4666 185.646 42.2872 186.492 42.0308V27.6512H191.644V46.8753H186.531V45.7988C185.454 46.3883 184.531 46.7856 183.762 46.9907C183.019 47.2214 182.224 47.3367 181.379 47.3367Z"
                  fill="white"
                />
                <path
                  d="M202.938 47.3367C201.451 47.3367 200.298 47.106 199.477 46.6446C198.657 46.1833 198.068 45.4271 197.709 44.3762C197.35 43.3253 197.171 41.8771 197.171 40.0315V32.0343H194.902V27.6512H197.171V22.3069H202.323V27.6512H207.052V32.0343H202.323V39.9162C202.323 40.9158 202.412 41.6464 202.592 42.1077C202.771 42.5435 203.22 42.7485 203.937 42.7229L206.86 42.646L207.09 46.76C206.065 46.9907 205.258 47.1445 204.668 47.2214C204.104 47.2983 203.527 47.3367 202.938 47.3367Z"
                  fill="white"
                />
                <path
                  d="M210.407 27.6512H215.559V46.8753H210.407V27.6512ZM210.407 20H215.559V25.1905H210.407V20Z"
                  fill="white"
                />
                <defs>
                  <clipPath id="clip0_3151_13110">
                    <rect width="75" height="75" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </NavLink>
          </Col>
          <Col xs={8} className="d-none d-sm-block">
            <Row className="navs align-items-center justify-content-between h-100 fs-6 ">
              <ul className="navbar_one d-none d-lg-flex justify-content-end gap-5">
                <li className="nav_item">
                  <NavLink
                    className={`nav_item_link white__link`}
                    to="/services"
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav_item">
                  <NavLink className={`nav_item_link white__link`} to="/works">
                    Works
                  </NavLink>
                </li>
                <li className="nav_item">
                  <NavLink className={`nav_item_link white__link`} to="/about">
                    About
                  </NavLink>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  className={`nav_item nav_item_link white__link ${
                    activeProd && "active"
                  }`}
                  onClick={(e) => activeProducts(e)}
                >
                  Products
                </li>
                <li className="nav_item nav_item_btn ">
                  <Link
                    className={`nav_item_link`}
                    onClick={(e) => (e.preventDefault(), setSubActive(true))}
                    to="/#"
                  >
                    Subscribe 
                  </Link>
                </li>
                <li className="nav_item nav_item_btn ">
                  <button className="toggle-btn" onClick={sideToggle}>
                    <MenuDark />
                  </button>
                </li>
              </ul>
            </Row>
          </Col>
          <Col className="toggle_mob p-0">
            <button className="toggle-btn" onClick={sideToggle}>
              <MenuDark />
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderTerms;

const styles = {
  container: {
    width: "1200px !important",
  },
};
