import React from 'react';
import './header.scss';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import useWidth from '../../../Hooks/useWidth'
const Header = (props) => {
  const size = useWidth()

  return (
    <>
      <div className="header mt-5 mb-5">

          <Container>
                {props.data.title?<><p
                  className="header_title"
                  dangerouslySetInnerHTML={{ __html: props.data?.title }}
                ></p>
                <p
                  className="header_description"
                  dangerouslySetInnerHTML={{ __html: props.data?.description }}
                ></p></>:(
                  <div className="text-center">
                    <Skeleton
                      height={40}
                      width={'80%'}
                      style={{ marginBottom: '20px' }}
                    />
                    <Skeleton height={30} width={'100%'} />
                    <Skeleton height={30} width={'90%'} />
                  </div>
                )}
          </Container>
                     
      </div>
      {props.data.image?<div className="header_image ">
      {size > 600?  <picture>
            <source media="(max-width:600px)" srcSet={props.data.image} />
            <img src={props.data.image} alt="Flowers" style={{ width: '100%' }} />
          </picture>: <picture>
            <source media="(max-width:600px)" srcSet={props.data.mobileImage} />
            <img src={props.data.mobileImage} alt="Flowers" style={{ width: '100%' }} />
          </picture>}
      </div>:<Skeleton height={300} width={'100%'} />}
      <div className="header mt-5 mb-5">
          <p className="header_desc"
          dangerouslySetInnerHTML={{ __html: props.data?.smallDescription }}>
          </p>
        
      </div>
    </>
  );
};

export default Header;
