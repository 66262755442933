import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import {LinkActive} from '../../../store/linkactive'

import styles from './CV2022Header.scss'
function CV2022Header() {
    const dispatch = useDispatch()
    let linksUi = useSelector(state => state.main.links);
    let linkPageUi = useSelector(state => state.main.linkpage);
    let [links, setLinks] = useState([]);
    let [linkPage, setLinkPage] = useState([]);
    useEffect(() => {
        window.scrollTo(0,0)
        if (linksUi && linkPageUi){
            setLinks(linksUi);
            setLinkPage(linkPageUi)
        }

    },[linksUi,linkPageUi,dispatch])
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
  return (
    <div className='cv_header_container'>
        {/* top-right */}
        <img className='absolute__imgs' 
            src={linkPage.topRightImage}
            alt="topRightImage" 
        />
        
        {/* top-left */}
        <img className='absolute__imgs' 
            src={linkPage.topLeftImage}
            alt="topLeft" />

        {/* bottom-left */}
        <img className='absolute__imgs' 
            src={linkPage.bottomLeftImage}
            alt="bottomLeftImage" />

        {/* bottom-right */}
        <img className='absolute__imgs' 
            src={linkPage.bottomRightImage}
            alt="bottomRight" />
        
        {/* emoji-right */}
        <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92yy.png' alt="" />
        {/* emoji-left */}
        <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92ye.png' alt="" />
        
        <div className='cv_header text-center mt-5'>
            <img src={linkPage.personalImage} alt=""/>
            <h3 className='fs-1 mt-3'>
                {linkPage.titltEn}
            </h3>
            <p className='fs-4 d-block mt-3'>
                {linkPage.descriptionEn}
            </p>
        </div>
    </div>
  )
}

export default CV2022Header