import React, { useEffect, useState } from "react";
import LayoutiLinksHeader from "../../Components/LayoutiComponents/Global/LayoutiLinksHeader/LayoutiLinksHeader";
import Cursor from "../../Cursor";
import "./style.scss";
import axios from "axios";
import { FormGroup, Input, Label } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";

const UnsubscribePage = () => {
  const [activeProd, setActiveProd] = useState(false);
  const [data, setData] = useState(null);
  const [navData, setNavData] = useState(null);
  const params = useParams();
  const router = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}frontend/ourProducts`)
      .then((res) => {
        setData(res.data.data);
      });
    axios.get(`${process.env.REACT_APP_API}frontend/navbar`).then((res) => {
      setNavData(res.data.data);
      window.scrollTo(0, 0);
    });
  }, []);

  const unsub = () => {
    const searchQuery = params.token;
    axios
      .post("https://api.newsypro.com/api/unsubscribe", {
        LICENSE: "Layouti_V1B99BZYTMjPbAVM7NQU",
        email: searchQuery,
      })
      .then(() => {
        router("/");
      })
      .catch(() => {
        router("/");
      });
  };

  return (
    <>
      <LayoutiLinksHeader
        setActiveProd={setActiveProd}
        data={navData}
        activeProd={activeProd}
        setSubActive={() => null}
        nolinks
      />
      <Cursor />
      <div className="unsub_container">
        <div className="unsub_inner">
          <h1>Unsubscribe me from all mailings</h1>
          <p>If you have a moment, please let us know why you unsubscribed:</p>
          <form>
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="unsubscribeReason"
                    value="noLongerWish"
                  />
                  I no longer wish to receive these emails
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="unsubscribeReason"
                    value="neverAgreed"
                  />
                  I never agreed to receive these emails
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="unsubscribeReason"
                    value="notRelevant"
                  />
                  These emails are not relevant or interesting
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="unsubscribeReason"
                    value="tooFrequent"
                  />
                  I receive these emails too frequently
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="unsubscribeReason"
                    value="deceptiveContent"
                  />
                  This email contains deceptive and/or misleading content
                </Label>
              </FormGroup>
            </FormGroup>
          </form>
          <button onClick={unsub}>Unsubscribe</button>
        </div>
        <p className="copywright">
          2023- 2012 © Copyright by{" "}
          <a href="https://layouti.com">Layouti Design LLC</a>. All rights
          reserved.
        </p>
      </div>
    </>
  );
};

export default UnsubscribePage;
