import React, { useState } from 'react';
import Challenges from '../../../Components/ProductDetailsComponents/Challenges/Challenges';
import ProjectSteps from '../../../Components/ProductDetailsComponents/ProjectSteps/ProjectSteps';
import ScopeOfWork from '../../../Components/LayoutiComponents/WorksDetails/ScopeOfWork/ScopeOfWork';
import WorksDetailsCard from '../../../Components/ProductDetailsComponents/WorksDetailsCard/WorksDetailsCard';
import './worksdetails.scss';
import ProjectImages from '../../../Components/ProductDetailsComponents/ProjectImages/ProjectImages';
// import Results from '../../../Components/ProductDetailsComponents/Results/Results'
import ThanksForWatching from '../../../Components/ProductDetailsComponents/ThanksForWatching/ThanksForWatching';
import SameWorks from '../../../Components/ProductDetailsComponents/SameWorks/SameWorks';
import { useEffect } from 'react';
import { LayoutiActions } from '../../../store/Layouti';
import { useDispatch } from 'react-redux';
import { Slider } from './style';
import { useOutletContext, useParams } from 'react-router-dom';
import axiosConfig from '../../../axiosConfig';
import ProjectInformation from '../../../Components/ProductDetailsComponents/ProjectInformation/ProjectInformation';
import { MetaTags } from 'react-meta-tags';
import Persona from '../../../Components/ProductDetailsComponents/Persona/Persona';
import MobileApps from '../../../Components/ProductDetailsComponents/MobileApps/MobileApps';
import OurClient from '../../../Components/ProductDetailsComponents/OurClient/OurClient';
import ExportScreen from '../../../Components/ProductDetailsComponents/ExportScreen/ExportScreen';
import Branding from '../../../Components/ProductDetailsComponents/Branding/Branding';
import Indepth from '../../../Components/ProductDetailsComponents/Indepth/Indepth';
import Persentation from '../../../Components/ProductDetailsComponents/Persentation/Persentation';
function WorksDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const [pageData, setPageData] = useState(null);
  const [relatedProjects, setRelatedProjects] = useState(null);
  const [active, setActive] = useState('');
  const [num, setNum] = useState('');
  const [loadactive, setLoadActive] = useOutletContext()

  useEffect(() => {
    dispatch(LayoutiActions.setHeader(2));
  }, [dispatch]);

  function getData() {
    setLoadActive(true)
    axiosConfig
      .post(
        `frontend/work/project`,
        { name: params.id.split('_').join(' ') },
        {
          headers: {
            language: `en`,
          },
        },
      )
      .then((res) => {
        window.scrollTo(0, 0);
        let data = res.data.data.project;
        let relatedProjects = res.data.data.relatedProjects;
        setPageData(data);
        setRelatedProjects(relatedProjects);
        dispatch(LayoutiActions.setColor(data.color));
        setTimeout(() => {
          setLoadActive(false)
        }, 2000)
      });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <worksDetails className="works__details">
      <MetaTags>
        <title>
          {pageData?.projectName?.name
            ? pageData?.projectName?.name
            : 'Layouti Project'}
        </title>
      </MetaTags>
      {/* works details header */}
      <Slider
        bg={pageData?.image ? pageData?.image : ''}
        className="works_details_header"
      ></Slider>

      {/* works details header */}
      {pageData && (
        <WorksDetailsCard
          cardDetails={pageData}
          setActive={setActive}
          setNum={setNum}
        />
      )}
      <Indepth
        data={pageData?.InDepth?.cards}
        title={pageData?.ProjectName?.name}
        active={active}
        setActive={setActive}
        num={num}
      />
      {pageData?.ScopeOfWork?.scope && (
        <ScopeOfWork
          cardDetails={pageData?.ScopeOfWork}
          color={pageData?.color}
        />
      )}

      {pageData?.template == 1 && (
        <>
          <ProjectSteps cardDetails={pageData} />
          <Challenges
            cardDetails={pageData?.TemplateBody?.DesignAppChallenges}
            lets={pageData?.TemplateBody?.DesignAppLetCheck}
          />
          <ProjectInformation cardDetails={pageData?.TemplateBody} />
          <Persona data={pageData?.TemplateBody?.DesignAppPersona} />
          {pageData &&
            pageData?.TemplateBody?.DesignAppSectionsTwo.map(
              (project, index) => (
                <ProjectImages
                  key={index}
                  title={project?.title}
                  desc={project?.description}
                  img={project?.image}
                />
              ),
            )}
          <MobileApps data={pageData?.TemplateBody} />
          <ExportScreen data={pageData?.TemplateBody} />
          <Challenges cardDetails={pageData?.TemplateBody?.DesignAppResults} />
          {pageData?.OurClients && (
            <OurClient
              bg={
                'https://services.layouti.com/media/ProductPage/OurClients/1673967996-Group 7199.png'
              }
              data={pageData?.OurClients}
            />
          )}
          {pageData?.ThanksSection && (
            <ThanksForWatching cardsData={pageData?.ThanksSection}  color={pageData?.color}/>
          )}
        </>
      )}

      {pageData?.template === 2 &&
        pageData.TemplateBody.BodyBranding?.length > 0 && (
          <Branding data={pageData?.TemplateBody} />
        )}
      {pageData?.template === 2 &&
        pageData.TemplateBody.BodyBranding?.length === 0 && (
          <Persentation data={pageData?.TemplateBody} />
        )}

      <SameWorks cardsData={relatedProjects} />
    </worksDetails>
  );
}

export default WorksDetails;
