import React from "react";
import "./header.scss";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import useWidth from "../../Hooks/useWidth";
const Header = (props) => {
  const staticData = useSelector((state) => state.design.static);
  const size = useWidth();
  return (
    <>
      <div className="header mt-5 mb-5">
        {
          <Container>
            {staticData ? (
              <>
                <p
                  className="header_title"
                  dangerouslySetInnerHTML={{ __html: staticData?.title }}
                ></p>
                <p
                  className="header_description"
                  dangerouslySetInnerHTML={{ __html: staticData?.description }}
                ></p>
              </>
            ) : (
              <div className="text-center">
                <Skeleton
                  height={40}
                  width={"80%"}
                  style={{ marginBottom: "20px" }}
                />
                <Skeleton
                  style={{ marginBottom: "10px" }}
                  height={30}
                  width={"100%"}
                />
                <Skeleton height={30} width={"90%"} />
              </div>
            )}
          </Container>
        }
      </div>
      {staticData?.image ? (
        <div className="header_image ">
          {size > 600 ? (
            <picture>
              <source media="(max-width:600px)" srcSet={staticData?.image} />
              <img
                src={staticData?.image}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>
          ) : (
            <picture>
              <source
                media="(max-width:600px)"
                srcSet={staticData?.mobileImage}
              />
              <img
                src={staticData?.mobileImage}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>
          )}
        </div>
      ) : (
        <Skeleton height={300} width={"100%"} />
      )}
      <div className="header mt-5 mb-5 text-center">
        {staticData ? (
          <p
            className="header_desc"
            dangerouslySetInnerHTML={{ __html: staticData?.smallDescription }}
          ></p>
        ) : (
          <Skeleton width={"70%"} height={30} />
        )}
      </div>
    </>
  );
};

export default Header;
