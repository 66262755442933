import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import ScrollTop from "../../../Components/ScrollTop/ScrollTop";
import SideBar from "../../../Components/SideBar/SideBar";
import Cursor from "../../../Cursor";
import LayoutiHeader from "../../../Components/LayoutiComponents/Global/LayoutiHeader/LayoutiHeader";
import { useEffect } from "react";
import ProductsLayouti from "../../../Components/ProductsLayouti/ProductsLayouti";
import axios from "axios";
import { MetaTags } from "react-meta-tags";
import LoadingPage from "../../../Components/LoadingPage/LoadingPage";
import SubscribePopups from "../../../Components/SubscribePopups/PopUp/SubscribePopups";
import PopUp from "../../../Components/Popups/PopUp/PopUp";
const LayoutiIndex = () => {
  const [activeProd, setActiveProd] = useState(false);
  const [data, setData] = useState(null);
  const [navData, setNavData] = useState(null);
  const [loadactive, setLoadActive] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}frontend/ourProducts`)
      .then((res) => {
        setData(res.data.data);
      });
    axios.get(`${process.env.REACT_APP_API}frontend/navbar`).then((res) => {
      setNavData(res.data.data);
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <>
      <LayoutiHeader
        setActiveProd={setActiveProd}
        data={navData}
        activeProd={activeProd}
        setSubActive={setSubActive}
      />
      <SideBar />
      <SubscribePopups
        isOpen={subActive}
        closeModal={() => setSubActive(false)}
        setSuccessActive={setSuccessActive}
      />
      <PopUp
        type={"success"}
        msg={{
          descriptionSuccess:
            "Thank you for subscribing. We will send you our newsletter for updates on all design trends, websites, articles, and related UX/UX design topics.",
        }}
        isOpen={successActive}
        closeModal={() => setSuccessActive(false)}
      />
      {loadactive && <LoadingPage />}
      {activeProd && (
        <ProductsLayouti setActiveProd={setActiveProd} data={data} />
      )}
      <Cursor />
      <Outlet context={[loadactive, setLoadActive]} />
      <ScrollTop />
      <Footer />
    </>
  );
};

export default LayoutiIndex;
