import React, { useEffect, useState } from "react";
import LearnedThings from "../../../Components/LayoutiComponents/Home/LearnedThings";
import NeedLayouti from "../../../Components/LayoutiComponents/Home/NeedLayouti/NeedLayouti";
import Testimonial from "../../../Components/LayoutiComponents/Home/Testimonial/Testimonial";
import LayoutiProccessDesgin from "../../../Components/LayoutiProccessDesgin/LayoutiProccessDesgin";
import LayoutiProducts from "../../../Components/LayoutiProducts/LayoutiProducts";
import LayoutiSkills from "../../../Components/LayoutiSkills/LayoutiSkills";
import LayoutiSlider from "../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider";
import { LayoutiActions } from "../../../store/Layouti";
import { useDispatch } from "react-redux";
import axiosConfig from "../../../axiosConfig";
import Projects from "../LayoutiHomePage/Projects/Projects";
import "./button.scss";
import { useNavigate, useOutletContext } from "react-router-dom";

const LayoutiHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState([]);
  const [loadactive, setLoadActive] = useOutletContext();

  const [sliderContent, setSliderContent] = useState({
    img: "images/layouti/home-bg.png",
    head: "We convert your ideas to our version of creativity",
    text: "The full UI/UX designers crew is ready to provide innovative, highly functional, visually appealing, and feature-rich website UI/UX designs, mobile applications UX/UI design as well as branding.",
  });
  let changeSectionBG = () => {
    let layoutiProccessSesgin = document.querySelector(
      ".js-layouti_proccess_desgin"
    );
    let layoutiProduct = document.querySelector(".js-layouti_product");
    let tesmonial = document.querySelector(".js-tesmonial");
    let Things100 = document.querySelector(".js-100-things");

    // transiton between process and design section and our latest works section
    if (window.pageYOffset + 300 > layoutiProccessSesgin.offsetTop) {
      layoutiProccessSesgin.classList.add("activeUp");
      layoutiProduct.classList.add("activeUp");
    } else {
      layoutiProccessSesgin.classList.remove("activeUp");
      layoutiProduct.classList.remove("activeUp");
    }

    // transiton between process and design section and accomplish ui/ux
    if (window.pageYOffset + 300 > tesmonial.offsetTop) {
      layoutiProccessSesgin.classList.add("activeDown");
      tesmonial.classList.add("activeDown");
    } else {
      layoutiProccessSesgin.classList.remove("activeDown");
      tesmonial.classList.remove("activeDown");
    }

    // transiton between process and design section and accomplish ui/ux
    if (window.pageYOffset + 300 > Things100.offsetTop) {
      Things100.classList.add("active100Things");
      tesmonial.classList.add("active100Things");
    } else {
      Things100.classList.remove("active100Things");
      tesmonial.classList.remove("active100Things");
    }
  };

  useEffect(() => {
    dispatch(LayoutiActions.setHeader(1));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", changeSectionBG);
    return () => document.removeEventListener("scroll", changeSectionBG);
  }, []);

  function getData() {
    setLoadActive(true);
    axiosConfig
      .get(`frontend`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        setPageData(res.data.data);
        setTimeout(() => {
          setLoadActive(false);
        }, 1500);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSliderContent({
      img: pageData?.HeaderContent?.image
        ? pageData?.HeaderContent?.image
        : null,
      head: pageData?.HeaderContent?.title
        ? pageData?.HeaderContent?.title
        : null,
      text: pageData?.HeaderContent?.description
        ? pageData?.HeaderContent?.description
        : null,
    });
    window.scrollTo(0, 0);
  }, [pageData]);
  return (
    <>
     
      <LayoutiSlider
        data={sliderContent}
        sectionData={pageData?.HeaderContent}
      />
      <LayoutiSkills sectionData={pageData?.WhatWeWillServe} />
      <LayoutiProducts sectionData={pageData?.OurLastWork} />
      {pageData?.OurLastWork?.lastwork ? (
        <div>
          <Projects filteredProjects={pageData?.OurLastWork.lastwork} />
        </div>
      ) : (
        ""
      )}
      {pageData?.HeaderContent && (
        <div className="layouti_product_items_wrap container">
          <button onClick={() => navigate("/works")}>See Our Works</button>
        </div>
      )}
      <LayoutiProccessDesgin sectionData={pageData?.ProcessDesignSkills} />
      <Testimonial sectionData={pageData?.Testimonials} />
      <LearnedThings
        sectionData={pageData?.Things}
        cardsData={pageData?.Things?.cards}
      />
      <NeedLayouti sectionData={pageData?.NeedLayoutiForYourProject} />
    </>
  );
};

export default LayoutiHomePage;
