import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import "./links.scss";
import { useDispatch, useSelector } from "react-redux";
import { LinkActive } from "../../../store/linkactive";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const Links = () => {
  const dispatch = useDispatch();
  let [links, setLinks] = useState(null);
  let [contacts, setContacts] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    dispatch(LinkActive.setType("links"));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API}/frontend/newLink/social`)
      .then((res) => {
        window.scrollTo(0, 0);
        setLinks(res.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API}/frontend/newLink/contact`)
      .then((res) => {
        window.scrollTo(0, 0);
        setContacts(res.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API}/frontend/newLink/header`)
      .then((res) => {
        setHeader(res.data.data);
      });
  }, []);

  return (
    <div className="p-0">
      <div
        className="links_header_container"
        style={{ backgroundImage: `url("${header?.bg_image}")` }}
      >
        <div className="links_header text-center">
          {header?.image ? (
            <img className="main-img" src={header?.image} alt="" />
          ) : (
            <Skeleton circle width={230} height={230} />
          )}
          <h3 className="fs-1 mt-3">
            {header?.title || <Skeleton width={"80%"} height={30} />}
          </h3>
          <p className="fs-4 d-block">
            {header?.description || (
              <>
                <Skeleton width={"90%"} height={30} />
                <Skeleton width={"50%"} height={30} />
              </>
            )}
          </p>
        </div>
      </div>
      <div className="contact_info_Section">
        <h2>{contacts?.title}</h2>
        <div className="contact_cards">
          {contacts?.Cards?.map((item, i) => (
            <a href="#" className="contact_cards_link">
              <div className="d-flex align-items-center contact_cards_link_inner">
                <span>
                  <div>
                    {item.type === "phone" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.3516 2.5C18.0526 2.911 20.9766 5.831 21.3916 9.532"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.3516 6.04297C16.1226 6.38697 17.5066 7.77197 17.8516 9.54297"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.0315 12.4724C15.0205 16.4604 15.9254 11.8467 18.4653 14.3848C20.9138 16.8328 22.3222 17.3232 19.2188 20.4247C18.8302 20.737 16.3613 24.4943 7.68447 15.8197C-0.993406 7.144 2.76157 4.67244 3.07394 4.28395C6.18377 1.17385 6.66682 2.58938 9.11539 5.03733C11.6541 7.5765 7.04253 8.48441 11.0315 12.4724Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : item.type == "whatsapp" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.94939 20.0368C7.73048 19.9268 7.49016 19.8889 7.25179 19.9453C6.44132 20.136 5.13368 20.462 4.18213 20.7013C3.64117 20.8375 3.14886 20.3559 3.27437 19.813C3.5108 18.7875 3.83868 17.3612 3.99435 16.648C4.043 16.4262 4.01284 16.1975 3.91652 15.9922C3.34637 14.7712 3 13.4198 3 12.0002C3 6.48846 8.02139 2.09267 13.7151 3.16097C17.2926 3.8323 20.1677 6.70834 20.839 10.2849C21.9073 15.9786 17.5115 21 11.9998 21C10.59 21 9.18115 20.6595 7.94939 20.0368Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.60085 8.46082C7.2144 9.85408 8.26518 12.0675 10.1138 13.9161C11.9624 15.7648 14.1408 16.7805 15.5341 15.3941L15.6625 15.2656C16.221 14.7081 16.1393 13.7809 15.4923 13.3295C15.2831 13.1845 15.0642 13.0308 14.8316 12.8683C14.3286 12.5151 13.6407 12.5696 13.2058 13.0025L12.9704 13.2361C11.9575 12.6328 11.4438 12.0529 10.7598 11.0255L10.9933 10.7901C11.4253 10.3542 11.4798 9.6663 11.1276 9.16329C10.9641 8.93075 10.8114 8.71184 10.6655 8.50266C10.215 7.85565 9.28775 7.77489 8.72928 8.33239L8.60085 8.46082Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M3 10.5244C3 9.52223 3.48941 8.58331 4.31157 8.0112L10.4715 3.71942C11.3899 3.0792 12.6101 3.0792 13.5285 3.71942L19.6884 8.0112C20.5096 8.58331 21 9.52223 21 10.5244V16.369C21 19.1342 19.1552 20.7669 16.4183 20.7601H7.58173C4.84476 20.7669 3 19.1342 3 16.369V10.5244Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.38672 9.04102L9.92996 14.3145C11.1627 15.2865 12.8994 15.2865 14.1234 14.3145L20.622 9.04102"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.07422 12.0425C7.07422 10.4556 8.1406 9.53125 9.70222 9.53125H14.2742C15.8533 9.53125 16.9197 10.4556 16.9197 12.0425"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  <span className="d-md-flex d-block">
                    <p className="m-0">{item.title}</p>
                    <p className="m-0 d-md-none d-flex">{item?.info}</p>
                  </span>
                </span>
                <span className="d-md-flex d-none">{item?.info}</span>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div className="social_media_links">
        <h1 className="">
          {links ? links?.title : <Skeleton width={"50%"} height={30} />}
        </h1>
        <div className="links text-center">
          {links?.Cards.map((el) => (
            <a
              key={el.id}
              href={el.link}
              alt={el.title}
              className="social_media_link"
            >
              {el.title}
            </a>
          ))}

          {!links && <Skeleton count={10} width={"40%"} height={100} />}
        </div>
      </div>
    </div>
  );
};

export default Links;
