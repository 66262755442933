import React, { useEffect, useState } from "react";
import UiCards from "../../../Components/UiCards/UiCards";
import Header from "../../../Components/UiCards/Pages/Header";
import { useDispatch, useSelector } from "react-redux";
import { LinkActive } from "../../../store/linkactive";
import MetaTags from "react-meta-tags";
import ScrollToTop from "../../../Components/ScrollToTop/ScrollToTop";
import axios from "axios";
import { useOutletContext } from "react-router-dom";

const UIGenerator = () => {
  const dispatch = useDispatch();
  let ui = useSelector((state) => state.ui.ui);
  const [all, setAll] = useState([]);
  const [allCount, setAllCount] = useState(0);
  const [male, setMale] = useState([]);
  const [maleCount, setMaleCount] = useState(0);
  const [female, setFemale] = useState([]);
  const [femaleCount, setFemaleCount] = useState(0);
  const [data, setData] = useState([]);
  const [allPage, setAllPage] = useState(1);
  const [malePage, setMalePage] = useState(1);
  const [femalePage, setFemalePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadactive, setLoadActive] = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAll = () => {
    allPage > 1 && setLoading(true);
    allPage == 1 && setLoadActive(true);
    axios
      .get(`${process.env.REACT_APP_API}frontend/avatars?page=${allPage}`)
      .then((res) => {
        setAll((all) => all.concat(res.data.data.avatars));
        setAllCount(res.data.data.pagination.total);
        setAllPage((page) => page + 1);
        setLoading(false);
        setLoadActive(false);
        setTimeout(() => {
          allPage == 1 && setLoadActive(false);
        }, 1000);
        allPage == 1 && window.scrollTo(0, 0);
      });
  };

  const getFemale = () => {
    allPage > 1 && setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API}frontend/avatars?status=female&page=${femalePage}`
      )
      .then((res) => {
        setFemale((all) => all.concat(res.data.data.avatars));
        setFemaleCount(res.data.data.pagination.total);
        setMalePage((page) => page + 1);
        setLoading(false);
      });
  };

  const getMale = () => {
    allPage > 1 && setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API}frontend/avatars?status=male&page=${malePage}`
      )
      .then((res) => {
        setMale((all) => all.concat(res.data.data.avatars));
        setMaleCount(res.data.data.pagination.total);
        setFemalePage((page) => page + 1);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(LinkActive.setType("ui"));
    getAll();
    getMale();
    getFemale();
    axios
      .get(`${process.env.REACT_APP_API}frontend/uiGenerator`)
      .then((res) => {
        setData(res.data.data);
      });

    if (ui) {
      setAll(ui.allPersons);
      setMale(ui.malePersons);
      setFemale(ui.femalePersons);
    }
  }, [dispatch, ui]);

  return (
    <>
      <MetaTags>
        <title>UI Avatar | Layouti</title>
      </MetaTags>
      <Header data={data} />
      <UiCards
        male={male}
        maleCount={maleCount}
        female={female}
        femaleCount={femaleCount}
        all={all}
        allCount={allCount}
        getAll={getAll}
        getMale={getMale}
        getFemale={getFemale}
        loading={loading}
      />
      <ScrollToTop />
    </>
  );
};

export default UIGenerator;
