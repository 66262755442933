import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import HeaderOne from "../../../Components/HeaderOne/HeaderOne";
import ScrollTop from "../../../Components/ScrollTop/ScrollTop";
import SideBar from "../../../Components/SideBar/SideBar";
import Cursor from "../../../Cursor";
import Alert from "../../../Components/Alert/Alert";
import { useDispatch } from "react-redux";
import { avatarActions } from "../../../store/UiAvatarSlice";
import axios from "axios";
import "./style.scss";
import { MetaTags } from "react-meta-tags";
import LoadingPage from "../../../Components/LoadingPage/LoadingPage";
import SubscribePopups from "../../../Components/SubscribePopups/PopUp/SubscribePopups";
import PopUp from "../../../Components/Popups/PopUp/PopUp";
const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loadactive, setLoadActive] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}contents`).then((res) => {
      dispatch(avatarActions.setData(res.data.data));
    });
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API}frontend/avatars/navbar`)
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  return (
    <div className="uiavatar_index">
      <Cursor />
      <HeaderOne data={data} setSubActive={setSubActive} />
      <MetaTags>
        <link rel="icon" href={data?.favIcon} />
      </MetaTags>
      <Alert />
      <SubscribePopups
        isOpen={subActive}
        closeModal={() => setSubActive(false)}
        setSuccessActive={setSuccessActive}
      />
      <PopUp
        type={"success"}
        msg={{
          descriptionSuccess:
            "Thank you for subscribing. We will send you our newsletter for updates on all design trends, websites, articles, and related UX/UX design topics.",
        }}
        isOpen={successActive}
        closeModal={() => setSuccessActive(false)}
      />
      {loadactive && <LoadingPage />}
      <SideBar />
      <Outlet context={[loadactive, setLoadActive]} />
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default Index;
