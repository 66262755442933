import React, { useEffect, useState } from "react";
import Links from "../365Design/Links/Links";
import LayoutiLinksHeader from "../../Components/LayoutiComponents/Global/LayoutiLinksHeader/LayoutiLinksHeader";
import Cursor from "../../Cursor";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import Footer from "../../Components/Footer/Footer";
import SideBar from "../../Components/SideBar/SideBar";
import axios from "axios";

const LinksPage = () => {
  const [activeProd, setActiveProd] = useState(false);
  const [data, setData] = useState(null);
  const [navData, setNavData] = useState(null);
  const [loadactive, setLoadActive] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}frontend/ourProducts`)
      .then((res) => {
        setData(res.data.data);
      });
    axios.get(`${process.env.REACT_APP_API}frontend/navbar`).then((res) => {
      setNavData(res.data.data);
      window.scrollTo(0, 0);
    });
  }, []);


  



  return (
    <>
      <LayoutiLinksHeader
        setActiveProd={setActiveProd}
        data={navData}
        activeProd={activeProd}
        setSubActive={setSubActive}
      />
      <SideBar />
      <Links />
      <Cursor />
      <ScrollTop />
      <Footer />
    </>
  );
};

export default LinksPage;
