import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "./uicards.scss";
import classnames from "classnames";
import All from "./Pages/All";
import Male from "./Pages/Male";
import Female from "./Pages/Female";
import axios from "axios";
import { useDispatch } from "react-redux";
import { avatarActions } from "../../store/UiAvatarSlice";
import { InfinitySpin } from "react-loader-spinner";
import useWidth from "../../Hooks/useWidth";

const UiCards = (props) => {
  const dispatch = useDispatch();

  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [page, setPage] = useState(2);
  const size = useWidth();
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  function getMoreData(id) {
    if (id == 1) {
      props.getAll();
    } else if (id == 2) {
      props.getMale();
    } else {
      props.getFemale();
    }
  }

  return (
    <Container>
      <Row>
        {size > 600 ? (
          <Col md="12" className=" p-0">
            <Nav
              pills
              className="flex-row tabs_cont gap-3  justify-content-center flex-nowrap navs_aside"
            >
              <NavItem className="nav_tap">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "": true,
                    active: verticalActiveTab === "1",
                    tab_link: true,
                  })}
                  onClick={() => {
                    toggleVertical("1");
                  }}
                >
                  All ({props.allCount ? props.allCount : 0})
                </NavLink>
              </NavItem>

              <NavItem className="nav_tap">
                <NavLink
                  style={{ cursor: "pointer", borderRadius: 0 }}
                  className={classnames({
                    "": true,
                    active: verticalActiveTab === "2",
                    tab_link: true,
                  })}
                  onClick={() => {
                    toggleVertical("2");
                  }}
                >
                  Male ({props.maleCount ? props.maleCount : 0})
                </NavLink>
              </NavItem>

              <NavItem className="nav_tap">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "": true,
                    active: verticalActiveTab === "3",
                    tab_link: true,
                  })}
                  onClick={() => {
                    toggleVertical("3");
                  }}
                >
                  Female ({props.femaleCount ? props.femaleCount : 0})
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        ) : (
          <div className="menu_mobile">
            <select
              value={verticalActiveTab}
              onChange={(e) => toggleVertical(e.target.value)}
            >
              <option value={"1"}>
                All ({props.allCount ? props.allCount : 0})
              </option>
              <option value={"2"}>
                Male ({props.maleCount ? props.maleCount : 0})
              </option>
              <option value={"3"}>
                Female ({props.femaleCount ? props.femaleCount : 0})
              </option>
            </select>
          </div>
        )}
        <Col md="12  mt-md-4 mt-1 p-0">
          <TabContent
            activeTab={verticalActiveTab}
            className="text-muted table__content mt-4 mt-md-0"
          >
            <TabPane tabId="1">
              <All data={props.all} />
            </TabPane>
            <TabPane tabId="2">
              <Male data={props.male} />
            </TabPane>
            <TabPane tabId="3">
              <Female data={props.female} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      {props.loading ? (
        <div className="spin">
          <InfinitySpin color="#3aaa35" width="150" height="45" />
        </div>
      ) : (
        <button
          className="load_more"
          onClick={() => getMoreData(verticalActiveTab)}
        >
          Load More
        </button>
      )}
    </Container>
  );
};

export default UiCards;
