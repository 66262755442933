import React, { useEffect, useState } from "react";
import { copyImageToClipboard } from "copy-image-clipboard";
import copy from "copy-to-clipboard";

const Card = (props) => {
  const [data, setData] = useState(null);
  function copyImg(link) {
    let alert = document.querySelector(".alert_message");
    copyImageToClipboard(link).then(() => {
      alert.innerHTML = "Copied Image";
      alert.classList.toggle("active");
      setTimeout(() => {
        alert.classList.toggle("active");
      }, 1000);
    });
  }
  function copyText(e, type) {
    let alert = document.querySelector(".alert_message");
    copy(e.target.innerText);
    alert.innerHTML = type;
    alert.classList.toggle("active");
    setTimeout(() => {
      alert.classList.toggle("active");
    }, 1000);
  }

  useEffect(() => {
    setData(props.person);
  }, [props.person]);
  return (
    <>
      {data ? (
        <div className="ui__card">
          <div className="ui_card">
            <img
              className="person_img"
              src={data.Avatar?.avatar}
              onClick={(e) => copyImg(e.target.src)}
            />
            <div className="ui_card_body">
              <p onClick={(e) => copyText(e, "Copied Name")}>
                {data.fname + data.lname}
              </p>
              <p onClick={(e) => copyText(e, "Copied Position")}>
                {data.Position?.position}
              </p>
              <p onClick={(e) => copyText(e, "Copied Email")}>{data.Email}</p>
              <p onClick={(e) => copyText(e, "Copied Country")}>
                {data.Country?.nameEn}
              </p>
              <p onClick={(e) => copyText(e, "Copied Phone")}>{data.phone}</p>
              <p onClick={(e) => copyText(e, "Copied Address")}>
                {data.Address?.address}
              </p>
              <p onClick={(e) => copyText(e, "Copied Date")}>
                {data.BirthData}
              </p>
              <p onClick={(e) => copyText(e, "Copied Age")}>
                {data.Age} Years Old
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Card;
