import React, { useCallback, useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Card from './Card';
import './styles.scss'
import { FlexDiv, ProdDetailsTitle,RoundedArrowButton } from '../../../ProductDetailsComponents/Styled/Index';


const ScopeOfWork = ({cardDetails, color}) => {

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        if(sliderRef.current.swiper.isBeginning) {
            document.querySelector('.prev-arrow').classList.add("not_active")
        }
        document.querySelector('.next-arrow').classList.remove("not_active")

    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        if(sliderRef.current.swiper.isEnd) {
            document.querySelector('.next-arrow').classList.add("not_active")
        }
        document.querySelector('.prev-arrow').classList.remove("not_active")
    }, []);

    useEffect(() => {
        if(sliderRef.current.swiper.isBeginning) {
            document.querySelector('.prev-arrow').classList.add("not_active")
            document.querySelector('.next-arrow').classList.remove("not_active")
        }
        if(sliderRef.current.swiper.isEnd) {
            document.querySelector('.next-arrow').classList.add("not_active")
        }
    }, [cardDetails])

    return (
        <div className='scope__of__work'>
            <Container className="p-0">
                <Row>
                    <Col>
                        <ProdDetailsTitle>{cardDetails?.title}</ProdDetailsTitle>
                        <p className='scope_parag d-md-block d-none'>{cardDetails?.description}</p>
                    </Col>
                    <Col className='d-md-block d-none'>
                        <FlexDiv>
                            <RoundedArrowButton className={`prev-arrow`} onClick={handlePrev} c={color}>
                                <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2186 -2.31122e-06L0.989151 12.4475L2.36041e-06 13.5L0.989152 14.5525L13.2186 27L15.2869 24.8949L5.53024 14.9644L36 14.9644L36 12.0356L5.53024 12.0356L15.2868 2.10508L13.2186 -2.31122e-06Z" fill="white"/>
                                </svg>
                            </RoundedArrowButton> 
                            <RoundedArrowButton className={`next-arrow`}  onClick={handleNext} c={color}>
                                <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.7814 27L35.0108 14.5525L36 13.5L35.0108 12.4475L22.7814 5.37418e-06L20.7131 2.10509L30.4698 12.0356L-3.92469e-06 12.0356L-3.15656e-06 14.9644L30.4698 14.9644L20.7132 24.8949L22.7814 27Z" fill="white"/>
                                </svg>
                            </RoundedArrowButton> 
                        </FlexDiv>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <Swiper
                        scrollbar={{
                            hide: false,
                        }}
                        modules={[Scrollbar]}
                        ref={sliderRef}
                        // slidesPerView={3}
                        spaceBetween={50}
                        pagination={{
                        clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}  
                        className="card-swiper"
                        >
                            {
                                cardDetails&&cardDetails?.scope?.cards.map((card,index)=>((
                                    <SwiperSlide key={index}>
                                        <Card cardsData={card} cardIndex={index+1}/>
                                    </SwiperSlide>
                                )))
                            }
                        </Swiper>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}

export default ScopeOfWork