import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'
import { WorkDetailsCard } from './styles';

const WorksDetailsCard = ({cardDetails, setActive, setNum}) => {
   
    return (
        <WorkDetailsCard bg={cardDetails?.color}>
            <Container className='p-0'>
                <Row>
                    <Col md="5">
                    <Row >
                        {cardDetails?.launch&&<Col ><p className="Launch_parag">Launch Project: {cardDetails?.launch}</p></Col>}
                    </Row>
                        <Row></Row>
                        <Row className="mt-3">
                            <Col className="mt-3">
                                {cardDetails?.ProjectName?.name&&<h2 className="works_details_title">{cardDetails?.ProjectName?.name}</h2>}
                            </Col>
                        </Row>
                        <Row className='d-md-flex d-none'>
                            {cardDetails?.ProjectName?.slogen&&<p className="slogan">{cardDetails?.ProjectName?.slogen}</p>}
                        </Row>
                    </Col>
                    <Col md="7">
                        <Row className='d-md-flex d-none'>
                            {
                                cardDetails?.ProjectInformation&&cardDetails?.ProjectInformation.map((info,index)=>(
                                    <Col md="4" key={index}>
                                        <p className="works_info">{info?.label} {info?.title}</p>
                                    </Col>
                                ))
                            }
                            {
                                cardDetails?.ProjectInformation?.length === 0&&<Col md="4"><p className="works_info">&nbsp;</p></Col>
                            }
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                {cardDetails?.ProjectName?.description&&cardDetails.ProjectName.description.split('/').map((text,i) => <p className='works__desc' key={i}>{text}</p>)}
                            </Col>
                        </Row>

                        {cardDetails?<Row className="people mt-5 d-md-flex d-none">
                        {cardDetails.TeamMembers.length !== 0?<h5 className="people__title">People working in the project</h5>:null}
                            <Row className="mt-2"> 
                            {
                                cardDetails?.TeamMembers&&cardDetails?.TeamMembers.map((member,index)=>(
                                    <Col key={index}>
                                        <h5 className="people__title">{member?.label}</h5>
                                        <h5 className="people__title">{member?.memberName}</h5>
                                    </Col>
                                ))
                            }
                            </Row>
                        </Row>:null}
                    </Col>
                </Row>
               {cardDetails?.InDepth&&cardDetails?.InDepth?.cards.length !== 0?<Row className="indepth d-md-flex d-none">
             
                    <Col md="5">
                        <h3 className="indepth_title" onClick={() => (setActive("active"),setNum(""))}><u>{cardDetails.InDepth.title?cardDetails.InDepth.title:""}</u></h3>
                    </Col>
                    <Col md="7">
                        <Row>
                            {
                                cardDetails.InDepth?cardDetails.InDepth.cards.map((card,index)=>(
                                    <Col md="6" className="mb-3" key={index} onClick={() => (setActive("active"),setNum(card.category))} >
                                        <p className="m-0">0{index+1}.</p>
                                        <h3 className=''><u>{card?.headLine}</u></h3>
                                    </Col>
                                )):null
                            }   
                        </Row>
                    </Col>
                </Row>:null}

            </Container>
        </WorkDetailsCard>
    )
}

export default WorksDetailsCard