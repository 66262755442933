import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./headerone.scss";
import { useSelector } from "react-redux";
import useReactSimpleMatchMedia from "react-simple-matchmedia";
import { ExitButton, StartButton } from "./style";
import { ReactComponent as ExitButtonWhite } from "../../../../assets/images/ExitButtonWhite.svg";
import { ReactComponent as MenuWhite } from "../../../../assets/images/MenuWhite.svg";
import axiosConfig from "../../../../axiosConfig";
import useWidth from "../../../../Hooks/useWidth";
import Skeleton from "react-loading-skeleton";

const LayoutiLinksHeader = ({
  setActiveProd,
  activeProd,
  data,
  setSubActive,
  nolinks,
}) => {
  const matched = useReactSimpleMatchMedia("(max-width: 375px)");
  const navigate = useNavigate();
  const location = useLocation();
  const linkType = useSelector((state) => state.linkactive.type);
  const layoutiHeaderState = useSelector((state) => state.layouti.header);
  const layoutiColor = useSelector((state) => state.layouti.color);
  const [whiteActive, setWhiteActive] = useState(false);
  const [footerContent, setFooterContent] = useState({});
  const size = useWidth();

  function sideToggle() {
    document.querySelector(".sidebar").classList.toggle("active");
  }

  useEffect(() => {
    let offset = 0;
    let scrollFunction = (e) => {
      if (window.scrollY > offset) {
        document.querySelector(".header_layouti").classList.add("active");
        setActiveProd(false);
      } else {
        document.querySelector(".header_layouti").classList.remove("active");
      }
      if (window.scrollY <= 0) {
        document.querySelector(".header_layouti").classList.remove("active");
      }

      document.querySelector(".header_layouti").classList.add("white");

      if (window.scrollY > 200) {
        document.querySelector(".scroll_top").style.display = "flex";
      } else {
        document.querySelector(".scroll_top").style.display = "none";
      }
      offset = window.scrollY;
    };
    window.addEventListener("scroll", scrollFunction);
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, [matched]);

  useEffect(() => {
    switch (linkType) {
      case "ui":
        break;
      case "content":
        break;
      default:
        break;
    }
  }, [linkType]);

  let navigateToLastPage = () => {
    if (location.pathname.includes("works")) {
      navigate("/works");
    } else if (location.pathname.includes("blog")) {
      navigate("/blog");
    }
  };

  function getData() {
    axiosConfig
      .get(`/frontend/footer`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        setFooterContent(res.data.data.FooterContent);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const activeProducts = (e) => {
    e.target.classList.toggle("active");
    setActiveProd((a) => !a);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      className={`header_layouti`}
      style={{ background: "#fff", borderBottom: "1px solid #EAEAEA" }}
    >
      <Container className=" h-100">
        <Row className="align-items-center justify-content-between h-100">
          {layoutiHeaderState === 1 ? (
            <Col xs="2" className="header_logo p-0">
              {data ? (
                <Link to="/">
                  {!whiteActive ? (
                    <img
                      height={"45px"}
                      src={
                        size > 600 ? data?.darkImage : footerContent.darkMobile
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      height={"45px"}
                      src={
                        size > 600
                          ? data?.lightImage
                          : footerContent.lightMobile
                      }
                      alt=""
                    />
                  )}{" "}
                </Link>
              ) : (
                <>
                  <Skeleton
                    baseColor="#bcbcbc"
                    highlightColor="#E4E4E4"
                    width={60}
                    height={60}
                  />
                </>
              )}
            </Col>
          ) : (
            <Col xs="2" className="p-0">
              <ExitButton
                color={layoutiColor}
                onClick={() => navigateToLastPage()}
              >
                <ExitButtonWhite />
              </ExitButton>
            </Col>
          )}
          {!nolinks && (
            <>
              {layoutiHeaderState === 1 ? (
                <Col xs={6} className="d-none d-lg-block">
                  <Row className="navs align-items-center justify-content-between h-100 fs-6 ">
                    <ul className="navbar_one">
                      <li className="nav_item">
                        <NavLink
                          className={`nav_item_link ${
                            whiteActive ? "white__link" : ""
                          }`}
                          to="/services"
                        >
                          Services
                        </NavLink>
                      </li>
                      <li className="nav_item">
                        <NavLink
                          className={`nav_item_link ${
                            whiteActive ? "white__link" : ""
                          }`}
                          to="/works"
                        >
                          Works
                        </NavLink>
                      </li>
                      <li className="nav_item">
                        <NavLink
                          className={`nav_item_link ${
                            whiteActive ? "white__link" : ""
                          }`}
                          to="/about"
                        >
                          About
                        </NavLink>
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        className={`nav_item nav_item_link ${
                          activeProd && "active"
                        } ${whiteActive ? "white__link" : ""}`}
                        onClick={(e) => activeProducts(e)}
                      >
                        Products
                      </li>
                      <li className="nav_item">
                        <NavLink
                          className={`nav_item_link ${
                            whiteActive ? "white__link" : ""
                          }`}
                          to="/links"
                        >
                          Links
                        </NavLink>
                      </li>

                      <li className="nav_item nav_item_btn">
                        <Link
                          className={`nav_item_link`}
                          onClick={(e) => (
                            e.preventDefault(), setSubActive(true)
                          )}
                          to="/#"
                        >
                          Subscribe
                        </Link>
                      </li>
                      <li className="nav_item nav_item_btn  d-md-block d-none">
                        <button className="toggle-btn  " onClick={sideToggle}>
                          <MenuWhite
                            className={`menu-icon ${
                              !whiteActive ? "dark-menu" : ""
                            }`}
                          />
                        </button>
                      </li>
                    </ul>
                  </Row>
                </Col>
              ) : (
                <Col className=" p-0 d-flex  justify-content-end align-align-items-center gap-5">
                  <StartButton color={layoutiColor} to="/contact-us">
                    Start a project
                  </StartButton>
                  <button className="toggle-btn" onClick={sideToggle}>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 6V7.66667H23V6H3ZM3 12.6667V14.3333H23V12.6667H3ZM3 19.3333V21H23V19.3333H3Z"
                        fill={layoutiColor}
                      />
                    </svg>
                  </button>
                </Col>
              )}
              <Col className="toggle_mob p-0 ">
                <button className="toggle-btn" onClick={sideToggle}>
                  <MenuWhite
                    className={`menu-icon ${!whiteActive ? "dark-menu" : ""}`}
                  />
                </button>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default LayoutiLinksHeader;
