/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import styles from "./ContactUsHireUsForm.module.css";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as AttachmentIcon } from "../../../../assets/images/attachment.svg";
import { ReactComponent as TrueIcon } from "../../../../assets/images/true.svg";
import { NavLink } from "react-router-dom";
import axiosConfig from "../../../../axiosConfig";
import { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import { emailPattern } from "../../../../util/features";
import PopUp from "../../../Popups/PopUp/PopUp";
function ContactUsHireUsForm({ isVisible, setScrollToTopPage }) {
  let acceptedFilesTypes = ["pdf", "docx", "doc"];
  const fileUploadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, setError_msg] = useState(false);
  const [msg, setMsg] = useState({
    title: "",
    descriptionSuccess: "",
    descriptionError: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [popUpType, setPopUpType] = useState("success");
  const [fileUploadedName, setFileUploadedName] = useState("No fIle choosen");
  const [budget, setBudget] = useState([]);
  const [whatWeNeed, setWhatWeNeed] = useState([]);
  const [acceptCondition, setAcceptConditions] = useState(false);
  const acceptConditionRef = useRef(null);
  const formRef = useRef(null);
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const attachmentRef = useRef(null);
  const detailsRef = useRef(null);
  const needRef = useRef(null);
  const budgetRef = useRef(null);
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    need: [],
    details: "",
    attachment: null,
    budget: "",
  });
  let openAttachmentFileUpload = () => {
    fileUploadRef.current.click();
  };
  let changeuploadedFilename = () => {
    let file = fileUploadRef.current.files[0];
    if (!file) {
      return;
    }
    if (!acceptedFilesTypes.includes(file.name.split(".").reverse()[0])) {
      // setError_msg(true)
      // setMsg('Fill type is not supported')
      setIsOpen(true);
      setPopUpType("error");
      setMsg({ descriptionError: "Fill type is not supported" });
      return;
    }
    if (Math.floor(file.size / 1024) > 10 * 1024) {
      // setError_msg(true)
      // setMsg('Fill maximum size is 10MB')
      setIsOpen(true);
      setPopUpType("error");
      setMsg({ descriptionError: "Fill maximum size is 10MB" });
      return;
    }
    setFileUploadedName(file.name);
    handleChangeData("attachment", file);
  };
  function getWhatWeNeed() {
    axiosConfig
      .get(`frontend/iNeed`)
      .then((res) => {
        setWhatWeNeed(res.data.data);
      })
      .catch((err) => {});
  }
  function getBudget() {
    axiosConfig
      .get(`frontend/budget`)
      .then((res) => {
        setBudget(res.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getBudget();
    getWhatWeNeed();
  }, []);
  function removeRequiredClass(event) {
    if (acceptCondition) {
      acceptConditionRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (values.fullName) {
      fullNameRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (emailPattern.test(values.email)) {
      emailRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (values.details) {
      detailsRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (values.attachment) {
      attachmentRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (values.need.length != 0) {
      needRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
    if (values.budget) {
      budgetRef.current.classList.remove(
        styles["contact-us__form-field-required"]
      );
    }
  }
  function submitData() {
    if (!acceptCondition) {
      acceptConditionRef.current.classList.add(
        styles["contact-us__form-field-required"]
      );
    }
    if (!values.fullName) {
      fullNameRef.current.classList.add(
        styles["contact-us__form-field-required"]
      );
    }
    if (!values.email || !emailPattern.test(values.email)) {
      emailRef.current.classList.add(styles["contact-us__form-field-required"]);
    }
    if (!values.details) {
      detailsRef.current.classList.add(
        styles["contact-us__form-field-required"]
      );
    }
    if (!values.attachment) {
      attachmentRef.current.classList.add(
        styles["contact-us__form-field-required"]
      );
    }
    if (!values.need.length != 0) {
      needRef.current.classList.add(styles["contact-us__form-field-required"]);
    }
    if (!values.budget) {
      budgetRef.current.classList.add(
        styles["contact-us__form-field-required"]
      );
    }
    if (
      !values.fullName ||
      !values.email ||
      !values.details ||
      !acceptCondition ||
      !values.budget ||
      !values.attachment ||
      !values.need.length != 0
    ) {
      // setError_msg(true)
      // setMsg('Please Fill All Required Fileds')
      setIsOpen(true);
      setPopUpType("error");
      setMsg({ descriptionError: "Please Fill All Required Fields" });
      return;
    }
    let formData = new FormData();
    formData.append(`fullName`, values.fullName);
    formData.append(`email`, values.email);
    formData.append(`attachment`, values.attachment);
    formData.append(`details`, values.details);
    formData.append(`budget`, Number(values.budget));
    whatWeNeed &&
      whatWeNeed.map((item, index) => {
        formData.append(`need[${index}]`, Number(item.id));
      });
    setIsLoading(true);
    axiosConfig
      .post(`frontend/hireUs`, formData)
      .then((res) => {
        setIsLoading(false);
        setIsOpen(true);
        setPopUpType("success");
        setMsg({
          title: "Thanks You!",
          descriptionSuccess:
            "Your dref has been successfully reviewed, and we will contact you shortly!",
        });
        setScrollToTopPage((prevValue) => !prevValue);
        setValues({
          fullName: "",
          email: "",
          need: [],
          details: "",
          attachment: null,
          budget: "",
        });
        setAcceptConditions(false);
        setWhatWeNeed([]);
        setBudget([]);
        setFileUploadedName("No fIle choosen");
        formRef.current.reset();
      })
      .catch((err) => {
        setIsLoading(false);
        setIsOpen(true);
        setPopUpType("error");
        setMsg({ descriptionError: "Sorry but something going wrong!" });
      });
  }
  function handleChangeData(changedName, changedValue) {
    setValues((prevValue) => {
      return {
        ...prevValue,
        [changedName]: changedValue,
      };
    });
  }
  useEffect(() => {
    if (fullNameRef) {
      removeRequiredClass();
    }
  }, [values, acceptCondition]);
  return (
    <form
      className={`${styles["contact-us__hire-us-form"]} ${
        !isVisible && styles["contact-us__hire-us-form--hidden"]
      }`}
      ref={formRef}
    >
      <PopUp
        type={popUpType}
        msg={msg}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="6" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__name"
                className={styles["contact-us__form-field-label"]}
              >
                Full name{" "}
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="contact-us__name"
                className={styles["contact-us__form-field-input"]}
                placeholder="e.g. Yasser Nazzel"
                name="fullName"
                value={values.fullName}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={fullNameRef}
              />
            </Col>

            <Col lg="6" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__email"
                className={styles["contact-us__form-field-label"]}
              >
                Email address
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <input
                type="email"
                id="contact-us__email"
                className={styles["contact-us__form-field-input"]}
                placeholder="e.g. hello@layouti.com"
                name="email"
                value={values.email}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={emailRef}
              />
            </Col>

            <Col lg="12" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__email"
                className={styles["contact-us__form-field-label"]}
                ref={needRef}
              >
                I need{" "}
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <div className={styles["contact-us__form-field-need-cont"]}>
                {whatWeNeed &&
                  whatWeNeed.map((item, index) => (
                    <div
                      className={styles["contact-us__form-field-select-cont"]}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        name="need"
                        value={item.id}
                        id={`user-need-${index}`}
                        className={styles["contact-us__check-box"]}
                        // name='fullName'
                        // value={values.fullName}
                        onChange={(e) => {
                          handleChangeData(e.target.name, e.target.value);
                        }}
                        // ref={fullNameRef}
                      />
                      <label
                        htmlFor={`user-need-${index}`}
                        className={styles["contact-us__check-box-label"]}
                      >
                        {item.title}
                      </label>
                    </div>
                  ))}
              </div>
            </Col>

            <Col lg="12" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__project-details"
                className={styles["contact-us__form-field-label"]}
              >
                Project details{" "}
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <textarea
                id="contact-us__project-details"
                className={styles["contact-us__form-field-textarea"]}
                placeholder="e.g. Enter your project details"
                rows="10"
                name="details"
                value={values.details}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={detailsRef}
              ></textarea>
            </Col>

            <Col lg="12" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__attachment"
                className={styles["contact-us__form-field-label"]}
              >
                Attachment{" "}
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <input
                type="file"
                ref={fileUploadRef}
                id="contact-us__attachment"
                className={styles["contact-us__form-field-input-file"]}
                onChange={changeuploadedFilename}
                accept=".pdf,.docx,.doc"
              />

              <div
                className={`${styles["contact-us__form-field-input"]} ${styles["contact-us__form-field-file--upload"]}`}
                onClick={openAttachmentFileUpload}
                ref={attachmentRef}
              >
                <span className={styles["contact-us__file-upload-text"]}>
                  {fileUploadedName}
                </span>
                <AttachmentIcon
                  className={styles["contact-us__file-upload-icon"]}
                />
              </div>
              <p className={styles["contact-us__file-upload-note"]}>
                Only PDF & Doc. with max. file size of 10MB
              </p>
            </Col>

            <Col lg="12" className={styles["contact-us__form-field-cont"]}>
              <label
                htmlFor="contact-us__email"
                className={styles["contact-us__form-field-label"]}
                ref={budgetRef}
              >
                Budget{" "}
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
              <div className={styles["contact-us__form-field-need-cont"]}>
                {budget &&
                  budget.map((item, index) => (
                    <div
                      className={styles["contact-us__form-field-select-cont"]}
                      key={index}
                    >
                      <input
                        type="radio"
                        name="budget"
                        value={item.id}
                        id={`user-budget-${index}`}
                        className={styles["contact-us__check-box"]}
                        onChange={(e) => {
                          handleChangeData(e.target.name, e.target.value);
                        }}
                      />
                      <label
                        htmlFor={`user-budget-${index}`}
                        className={styles["contact-us__check-box-label"]}
                      >
                        {item.title}
                      </label>
                    </div>
                  ))}
              </div>
            </Col>

            <Col
              lg="12"
              className={`${styles["contact-us__form-field-cont"]} ${styles["contact-us__form-field-privacy-cont"]}`}
            >
              <input
                type="checkbox"
                name="privacy-check"
                id="terms-conditions"
                className={styles["contact-us__terms-conditions-input"]}
                value={acceptCondition}
                onChange={(e) => {
                  setAcceptConditions(!acceptCondition);
                }}
                ref={acceptConditionRef}
              />
              <label
                htmlFor="terms-conditions"
                className={styles["contact-us__terms-conditions-label"]}
                ref={acceptConditionRef}
              >
                <span
                  className={styles["contact-us__terms-conditions-input-shown"]}
                >
                  <TrueIcon
                    className={styles["contact-us__terms-conditions-icon"]}
                  />
                </span>
                I accept you{" "}
                <NavLink
                  to="/"
                  className={styles["contact-us__form-field-privacy"]}
                >
                  {" "}
                  Terms & Conditions
                </NavLink>
                <span className={styles["contact-us__form-field-required"]}>
                  *
                </span>
              </label>
            </Col>

            <div className={styles["contact-us__submit-form-cont"]}>
              <button
                type="button"
                onClick={submitData}
                className={styles["contact-us__submit-form"]}
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Send request"
                )}
              </button>
            </div>
          </Row>
        </Col>
      </Row>
    </form>
  );
}

export default ContactUsHireUsForm;
