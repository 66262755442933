import React from "react";
// import { useEffect } from 'react'
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Cursor from "../../Cursor";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import Footer from "../../Components/Footer/Footer";
import HeaderTerms from "../../Components/HeaderTerms/HeaderTerms";
import ProductsLayouti from "../../Components/ProductsLayouti/ProductsLayouti";
import axios from "axios";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import SubscribePopups from "../../Components/SubscribePopups/PopUp/SubscribePopups";
import PopUp from "../../Components/Popups/PopUp/PopUp";
const TermsIndex = () => {
  const [activeProd, setActiveProd] = useState(false);
  const [data, setData] = useState(null);
  const [loadactive, setLoadActive] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadActive(true);
    axios
      .get("https://services.layouti.com/api/frontend/ourProducts")
      .then((res) => {
        setData(res.data.data);
        setLoadActive(false);
      });
  }, []);
  return (
    <>
      <HeaderTerms setActiveProd={setActiveProd} activeProd={activeProd} setSubActive={setSubActive} />
      {activeProd && (
        <ProductsLayouti data={data} setActiveProd={setActiveProd} />
      )}
      {loadactive && <LoadingPage />}
      <SubscribePopups
        isOpen={subActive}
        closeModal={() => setSubActive(false)}
        setSuccessActive={setSuccessActive}
      />
      <PopUp
        type={"success"}
        msg={{
          descriptionSuccess:
            "Thank you for subscribing. We will send you our newsletter for updates on all design trends, websites, articles, and related UX/UX design topics.",
        }}
        isOpen={successActive}
        closeModal={() => setSuccessActive(false)}
      />
      <Cursor />
      <Outlet />
      <ScrollTop />
      <Footer />
    </>
  );
};

export default TermsIndex;
