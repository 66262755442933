import React from "react";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./Router/AnimatedRoutes";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
