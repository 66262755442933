import React, { useState, useEffect } from "react";
import { Container, Col, Row, Collapse } from "reactstrap";
import "./footer.scss";
import classnames from "classnames";
// import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as TopRightArrow } from "../../assets/images/TopRightArrow.svg";
import axiosConfig from "../../axiosConfig";

const Footer = () => {
  const [active, setActive] = useState({
    ui: "",
    content: "",
  }); /* eslint-disable-line */

  const [footerOurProducts, setFooterOurProducts] = useState([]);
  const [footerInHouseWorks, setFooterInHouseWorks] = useState([]);
  const [footerContent, setFooterContent] = useState({});
  const [footerColors, setFooterColors] = useState({});
  const [footerSocialMedia, setFooterSocialMedia] = useState([]);
  const linkType = useSelector((state) => state.linkactive.type);
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false); /* eslint-disable-line */
  const [col5, setcol5] = useState(false); /* eslint-disable-line */

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol5(false);
  };

  useEffect(() => {
    switch (linkType) {
      case "ui":
        setActive({ ui: "active", content: "" });
        break;
      case "content":
        setActive({ ui: "", content: "active" });
        break;
      default:
        break;
    }
  }, [linkType]);

  function getData() {
    axiosConfig
      .get(`/frontend/footer`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        setFooterOurProducts(res.data.data.FooterOurProducts);
        setFooterInHouseWorks(res.data.data.FooterInHouseWorks);
        setFooterContent(res.data.data.FooterContent);
        setFooterColors(res.data.data.FooterColors);
        setFooterSocialMedia(res.data.data.FooterSocialMedia);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="footer">
      <div
        style={{ backgroundColor: footerColors.firstColor }}
        className="footer__head"
      >
        <Container>
          <Row>
            <Col md="6">
              <h2 className="footer__external__title">Our Projects</h2>
              {footerOurProducts.map((product, idx) => (
                <Row key={idx} className="mb-4">
                  <Col xs="9">
                    <div className="footer__link__row d-flex align-items-center gap-4">
                      <img src={product.image} alt={product.title} />
                      <h3 className="m-0">{product.title}</h3>
                    </div>
                  </Col>
                  <Col
                    xs="3"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <a
                      href={product.link}
                      rel="noopener noreferrer"
                      className="external__link"
                    >
                      <TopRightArrow />
                    </a>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col className="second__col" md="6">
              <h2 className="footer__external__title">In-house Products</h2>
              {footerInHouseWorks.map((item, idx) => (
                <Row className="mb-4">
                  <Col xs="9">
                    <div className="footer__link__row d-flex align-items-center gap-4">
                      <img src={item.image} alt={item.title} />
                      <h3 className="m-0">{item.title}</h3>
                    </div>
                  </Col>
                  <Col
                    xs="3"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <a href={item.link} className="external__link">
                      <TopRightArrow />
                    </a>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{ background: footerColors.dividerColor }}
        className="devider"
      ></div>
      <div
        style={{ backgroundColor: footerColors.meddleColor }}
        className="footer__container"
      >
        <Container>
          <Row className="footer_content">
            <Col lg={5} sm={12} className="foot_head">
              <Row className="align-items-center flex-nowrap m-0">
                <Col lg={2} xs={3} className="p-0">
                  <NavLink to="/">
                    <img
                      src={footerContent.logo}
                      alt="Layouti logo"
                      height="50px"
                    />
                  </NavLink>
                </Col>
                <Col lg={10} xs={9} className="p-0"></Col>
              </Row>
              <Row className="mt-2 m-0">
                <p className="footer_slug m-0 ps-0 py-3 fs-6 fw-bold">
                  {footerContent.slogan}
                </p>
                <p className="p-0 footer_desc  fs-6 fw-lighter">
                  {footerContent.description}
                </p>
              </Row>
              <Row className="mt-3 m-0">
                <div className="footer_social">
                  {footerSocialMedia.map((item) => (
                    <span>
                      <a href={item.link}>
                        <img src={item.image} alt="" />{" "}
                      </a>
                    </span>
                  ))}
                </div>
              </Row>
            </Col>
            <Col lg={7} sm={12} className="desc_foot">
              <Row>
                <Col>
                  <h3 className="fs-5 fw-bolder">Main Menu</h3>
                  <ul className="footer_navs">
                    <li className="">
                      <NavLink className="footer_navs_link" exact="true" to="/">
                        Home
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/services" className="footer_navs_link">
                        Services
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/works" className="footer_navs_link">
                        Works
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about" className="footer_navs_link">
                        About
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/contact-us" className="footer_navs_link">
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/100-things" className="footer_navs_link">
                        100 Things
                      </NavLink>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <h3 className="fs-5 fw-bolder">UI/UX Education</h3>
                  <ul className="footer_navs">
                    <li className="">
                      <a
                        href="https://resourchub.com/resources"
                        className="footer_navs_link"
                      >
                        ResourcHub
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="https://uiuxedu.com"
                        className="footer_navs_link"
                      >
                        Design Process
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="https://uiuxedu.com/articles"
                        className="footer_navs_link"
                      >
                        Articles
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="https://www.uiuxedu.com/academy"
                        className="footer_navs_link"
                      >
                        Academy
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <h3 className="fs-5 fw-bolder">Other Links</h3>
                      <ul className="footer_navs">
                        <li>
                          <Link className="footer_navs_link" to="/365design">
                            365design
                          </Link>
                        </li>
                        <li>
                          <Link className="footer_navs_link" to="/uiavatar">
                            UI Avatar
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="footer_navs_link"
                            to="/uiavatar/contentgenerator"
                          >
                            Content Generator
                          </Link>
                        </li>
                        <li>
                          <Link className="footer_navs_link" to="/links">
                            Links
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="footer_navs_link"
                            to="/365design/products"
                          >
                            Products
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <h3 className="fs-5 fw-bolder">Help?</h3>
                      <ul className="footer_navs">
                        <li>
                          <Link
                            className="footer_navs_link"
                            to="/term&condition"
                          >
                            Terms & Conditions
                          </Link>
                        </li>
                        <li>
                          <a className="footer_navs_link" href="/#">
                            Sitemap
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={6} sm={12} className="mt-4 p-0 mobile_foot">
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button",
                        "accordion-button-footer",
                        "fw-medium",
                        { collapsed: !col1 }
                      )}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      <h3 className="fs-md-5 fs-6 m-0">Main Menu</h3>
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <ul className="footer_navs">
                        <li className="">
                          <NavLink
                            to="/"
                            exact="true"
                            className="footer_navs_link"
                          >
                            Home
                          </NavLink>
                        </li>
                        <li className="">
                          <NavLink to="/services" className="footer_navs_link">
                            Services
                          </NavLink>
                        </li>
                        <li className="">
                          <NavLink to="/works" className="footer_navs_link">
                            Works
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/about" className="footer_navs_link">
                            About
                          </NavLink>
                        </li>
                        <li className="">
                          <NavLink
                            to="/contact-us"
                            className="footer_navs_link"
                          >
                            Contact Us
                          </NavLink>
                        </li>
                        <li className="">
                          <NavLink
                            to="/100-things"
                            className="footer_navs_link"
                          >
                            100 Things
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames(
                        "accordion-button",
                        "accordion-button-footer",
                        "fw-medium",
                        { collapsed: !col2 }
                      )}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer" }}
                    >
                      <h3 className="fs-md-5 fs-6 m-0">Other Links</h3>
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <ul className="footer_navs">
                        <li>
                          <Link to="/uiavatar" className="footer_navs_link">
                            UI Avatar
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/uiavatar/contentgenerator"
                            className="footer_navs_link"
                          >
                            Content Generator
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/365design/links"
                            className="footer_navs_link"
                          >
                            Links
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/365design/products"
                            className="footer_navs_link"
                          >
                            Products
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={classnames(
                        "accordion-button",
                        "accordion-button-footer",
                        "fw-medium",
                        { collapsed: !col3 }
                      )}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer" }}
                    >
                      <h3 className="fs-md-5 fs-6 m-0">Helps?</h3>
                    </button>
                  </h2>
                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <ul className="footer_navs">
                        <li>
                          <a className="footer_navs_link" href="/#">
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a className="footer_navs_link" href="/#">
                            Sitemap
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{ background: footerColors.dividerColor }}
        className="devider"
      ></div>
      <div
        style={{ backgroundColor: footerColors.lastColor }}
        className="footer__foot"
      >
        <Container className="h-100">
          <div className="justify-content-between  align-items-center d-flex  flex-wrap h-100">
            <p className="fs-6 fw-lighter  m-0 d-block">
              <div
                dangerouslySetInnerHTML={{ __html: footerContent.copyWriter }}
              />
            </p>
            <p className="fs-6 d-md-block d-none fw-lighter m-0 d-block">
              <div
                dangerouslySetInnerHTML={{ __html: footerContent.createdBy }}
              />
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
