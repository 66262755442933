import React, { useState } from "react";
import { ReactComponent as SuccessPopUp } from "../../../assets/images/successPopUp.svg";
import { ReactComponent as FaliurePopUp } from "../../../assets/images/failurePopUp.svg";
import styles from "./PopUp.module.css";
import { Col } from "reactstrap";
import axios from "axios";
function SubscribePopups({ isOpen, closeModal, type, msg, setSuccessActive }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://api.newsypro.com/api/subscribe", {
        email,
        system: "Layouti",
        LICENSE: "Layouti_V1B99BZYTMjPbAVM7NQU",
      })
      .then((res) => {
        setEmail("");
        setLoading(false);
        setSuccessActive(true);
        closeModal();
      });
  };

  return (
    <div
      className={`${styles["popup"]} ${isOpen ? styles["popup-active"] : ""}`}
    >
      <div
        className={styles["popup-background-color"]}
        onClick={closeModal}
      ></div>
      <div className={styles["popup-container"]}>
        <div className={styles["popup-header"]}>
          <h2 className={styles["popup-header__title"]}>
            {"Subscribe with Layouti"}
          </h2>
        </div>
        <div className={styles["popup-image__container"]}>
          <label
            htmlFor="contact-us__email"
            className={styles["contact-us__form-field-label"]}
          >
            Email address
            <span className={styles["contact-us__form-field-required"]}>*</span>
          </label>
          <input
            type="email"
            id="contact-us__email"
            className={styles["contact-us__form-field-input"]}
            placeholder="e.g. hello@layouti.com"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles["popup-footer__close-container"]}>
          <button
            type="button"
            onClick={closeModal}
            className={styles["popup-footer__close-button"]}
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className={styles["popup-footer__submit-button"]}
          >
            {!loading ? (
              "Submit"
            ) : (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SubscribePopups;
