import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { 
        Container,
        Row,
        Col
    } from 'reactstrap'
import './headerone.scss'
import { useSelector } from 'react-redux'
import {ReactComponent as LearnLogo} from '../../assets/images/learnLogo.svg'
import {ReactComponent as MenuDark} from '../../assets/images/MenuDark.svg'

const HeaderLearn = () => {
    const [active, setActive] = useState({ui:'', content: ''})
    const linkType = useSelector(state => state.linkactive.type)
    function sideToggle () {
        document.querySelector('.sidebar').classList.toggle('active')
    }
    useEffect(() => {
        let offset = 0
        let scrollFunction =(e) => {
            if(window.scrollY > offset) {
                document.querySelector('.header_one').classList.add('active') 
            }else{
                document.querySelector('.header_one').classList.remove('active') 
            }
            if(window.scrollY > 200) {
                document.querySelector('.scroll_top').style.display = "flex"
            }else {
                document.querySelector('.scroll_top').style.display = "none"
            }
            offset = window.scrollY
        }
        window.addEventListener('scroll', scrollFunction)
        return ()=>{
            window.removeEventListener('scroll', scrollFunction)
        }
    }, [])
    
    useEffect(() => {
        switch (linkType) {
            case "ui":
                setActive({ui:'active', content: ''})
                break;
            case "content":
                setActive({ui:'', content: 'active'})
                break;
            default:
                break;
        }
    }, [linkType])
    return (
        <div className={`header_one`}>
            <Container className=' h-100' style={styles.container}>
                <Row className='align-items-center justify-content-between h-100'>
                    <Col xs="2" className='p-0'>
                        <NavLink to=''>
                            <LearnLogo/>
                        </NavLink>
                    </Col>
                    <Col xs={8}className="d-none d-sm-block">
                        <Row className='navs align-items-center justify-content-between h-100 fs-6 '>
                            <ul className='navbar_one d-none d-lg-flex'>
                                <li className='nav_item'>
                                    <Link className={`nav_item_link ${active.ui}`} to='/uiavatar'>Courses</Link>
                                </li>
                                <li>
                                    <Link className='nav_item_link' to='/365design'>365 Design</Link>
                                </li>
                                <li className='nav_item'>
                                    <Link className={`nav_item_link`} to='/365design/products'>Products</Link>
                                </li>
                                <li className='nav_item'>
                                    <Link className='nav_item_link' to='/365design/links'>Links</Link>
                                </li>
                                <li className='nav_item nav_item_btn '>
                                    <Link className={`nav_item_link`} to='/contact-us?scroll=true'>Subscribe </Link>
                                </li>
                                {/* <li className='nav_item nav_item_btn '>
                                    <button className='toggle-btn  toggle-btn--lang' >
                                        العربية
                                    </button>
                                </li> */}
                                <li className='nav_item nav_item_btn '>
                                    <button className='toggle-btn' onClick={sideToggle}>
                                        <MenuDark/>
                                    </button>
                                </li>
                            </ul>
                        </Row>
                    </Col>
                    <Col className='toggle_mob p-0'>
                        <button className='toggle-btn' onClick={sideToggle}>
                            <MenuDark/>
                        </button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HeaderLearn

const styles={
    container:{
        width:'1200px !important'
    }
}